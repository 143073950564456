/**
 * Javascript for InjectorMain
 *
 **/

import InjectorAds from "./injector-ads";
import InjectorBanners from "./injector-banners";
import InjectorNewsletterSignup from "./injector-newsletter-signup";
import InjectorVideos from "./injector-videos";
import InjectorRightrail from "./injector-rightrail";
import InjectorTouts from "./injector-touts";
import InjectorCommerce from "./injector-commerce";
import InjectorCustom from "./injector-custom";
import InjectorPromo from "./injector-promo";

const injectorAds = new InjectorAds();
const injectorBanners = new InjectorBanners();
const injectorSignup = new InjectorNewsletterSignup();
const injectorVideos = new InjectorVideos();
const injectorRightrail = new InjectorRightrail();
const injectorTouts = new InjectorTouts();
const injectorCommerce = new InjectorCommerce();
const injectorCustom = new InjectorCustom();
const injectorPromo = new InjectorPromo();

function InjectorMain() {
  this.init = function () {
    injectorRightrail.init(); // Run first.
    injectorAds.init();
    this.getWidgets();
    $(window).on("injector:scroll_update", this, this.getWidgets);
    $(window).on("injector:inject_widgets", this, this.injectWidgets);
  };

  this.injectWidgets = (_, $article, priorities) => {
    const subscribeDisplayed = sessionStorage.getItem(
      "ess_nl_inline_displayed"
    );

    let rulesUsed = [];
    let rightrail = null;
    for (const p in priorities) {
      if ("rightrail" === p) {
        rightrail = priorities[p];
      } else if (
        Object.hasOwn(priorities, p) &&
        /^0$|^[1-9]\d*$/.test(p) &&
        p <= 4294967294
      ) {
        const priority = priorities[p];
        // Try/Catch to prevent widget injection errors from breaking the rightrail.
        try {
          for (const name in priority) {
            let widgets = priority[name];

            for (const w in widgets) {
              if (
                Object.hasOwn(widgets, w) &&
                /^0$|^[1-9]\d*$/.test(w) &&
                w <= 4294967294
              ) {
                const widget = widgets[w];

                if (
                  typeof widget === "object" &&
                  !Array.isArray(widget) &&
                  widget !== null
                ) {
                  let rule = widget.rule;
                  let rand = Math.floor(Math.random() * 100);

                  // Banner frequency is handled in the backend.
                  if ("banners" === name || rand < widget.frequency) {
                    // Allow to disable some injections.
                    if (
                      typeof pubstack.allowedInjections !== "undefined" &&
                      !pubstack.allowedInjections.includes(name)
                    ) {
                      continue;
                    }
                    switch (name) {
                      case "ads":
                        if (!rulesUsed.includes(rule) || 1) {
                          // Only one rule for ads
                          // widget contains mobile, desktop and between_article ads
                          injectorAds.betweenArticle($article, widget);
                          injectorAds.everyThird($article, widget);
                          $(window).trigger("ad:scroll_update");
                        }
                        break;
                      case "banners":
                        if (!rulesUsed.includes(rule) || 1) {
                          // Only one rule for banners
                          injectorBanners.middleParagraph(
                            $article,
                            widget.html
                          );
                        }
                        break;
                      case "videos":
                        // Allows for backup to place video at end
                        if (rulesUsed.includes(rule) && rule === "middle") {
                          rule = "end";
                        }
                        if (
                          (!rulesUsed.includes(rule) || 1) &&
                          undefined !== widget.html
                        ) {
                          if (rule === "middle") {
                            injectorVideos.middleParagraph(
                              $article,
                              widget.html
                            );
                          } else {
                            injectorVideos.endParagraph($article, widget.html);
                          }
                        }
                        break;
                      case "newsletter":
                        if (!rulesUsed.includes(rule) || 1) {
                          // Only one rule for newsletter subscribe
                          if (!subscribeDisplayed && widget !== false) {
                            injectorSignup.fifthParagraph(
                              $article,
                              widget.html
                            );
                          } else {
                            rule = null;
                          }
                        }
                        break;
                      case "touts":
                        if (!rulesUsed.includes(rule) || 1) {
                          // Only one rule for touts
                          injectorTouts.lastThirdParagraph(
                            $article,
                            widget.html
                          );
                        }
                        break;
                      case "commerce":
                        // Don't inject commerce widget in commerce articles
                        if ($article.attr("class").indexOf("commerce") === -1) {
                          if (!rulesUsed.includes(rule)) {
                            // Only one rule for commerce
                            injectorCommerce.lastThirdParagraph(
                              $article,
                              widget.html
                            );
                          }
                        }
                        break;
                      case "custom":
                        injectorCustom.middleParagraph($article, widget.html);
                        break;
                      case "promo":
                        injectorPromo.inject(
                          rule,
                          widget.rule_num,
                          $article,
                          widget.html
                        );
                        break;
                    }

                    // Allows multiple at end of content
                    if (rule !== "end") {
                      rulesUsed.push(rule);
                    }
                  }
                }
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
    // Inject rightrail after all other content injection so that float right will not look bad.
    if (
      !(
        typeof pubstack.allowedInjections !== "undefined" &&
        !pubstack.allowedInjections.includes("rightrail")
      ) &&
      rightrail
    ) {
      injectorRightrail.inject($article, rightrail);
      $(window).trigger("ad:scroll_update");
    }

    $(window).trigger("injector:inject_widgets_injected", [$article]);
  };
  this.getWidgets = (event = null, $article = null) => {
    let isFirst = true;
    if (null === $article) {
      isFirst = false;
      // First article. Otherwise, iscroll will send which article.
      $article = $("#main article").first();
    }

    const post_id = $article.data("id");
    let data = {
      isAjax: isFirst,
      articlesLoaded: $("#main .article").length,
    };

    if (post_id === undefined) {
      return false;
    }

    $.get(
      pubstack.restUrl + "pubstack/v1/injector-widgets/" + post_id,
      data,
      function (response) {
        $(window).trigger("injector:inject_widgets", [$article, response]);
      },
      "json"
    ).fail(function (e) {
      console.error("Could not load injector widgets.: ", e);
    });
  };
}

export default InjectorMain;
